import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/app/(landing)/assets/green-lines.svg");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/app/(landing)/assets/grid-bg.png");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/app/(landing)/assets/token-utility.png");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/app/(landing)/components/hero-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/app/(landing)/components/isomorphic-swap-animation.tsx");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/apps/web/src/assets/underline.svg");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/link.js");
