"use client";

import { motion } from "framer-motion";
import ChevronDown from "../assets/chevron-down.svg";

interface ScrollDownButtonProps {
  targetId: string;
}

export function ScrollDownButton({ targetId }: ScrollDownButtonProps) {
  const handleScroll = () => {
    document.getElementById(targetId)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <a
      onClick={handleScroll}
      onKeyDown={e => {
        if (e.key === "Enter") {
          handleScroll();
        }
      }}
      tabIndex={0}
      className="active:scale-95 absolute bottom-16 cursor-pointer"
    >
      <motion.div
        className="grid w-8"
        initial={{ y: 5 }}
        animate={{ y: 0 }}
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatDelay: 0.5,
          ease: "easeInOut",
        }}
      >
        <motion.div
          className="absolute inset-x-0 top-[5px]"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatDelay: 0.5,
            ease: "easeInOut",
          }}
        >
          <ChevronDown />
        </motion.div>
        <motion.div
          className="relative"
          initial={{ y: 0 }}
          animate={{ y: 20 }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatDelay: 0.5,
            ease: "easeInOut",
          }}
        >
          <ChevronDown />
        </motion.div>
        <motion.div
          className="absolute inset-x-0 bottom-[5px]"
          initial={{ opacity: 1, scale: 1, y: 20 }}
          animate={{ opacity: 0, scale: 0, y: 40 }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatDelay: 0.5,
            ease: "easeInOut",
          }}
        >
          <ChevronDown />
        </motion.div>
      </motion.div>
    </a>
  );
}
