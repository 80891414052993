"use client";

import { motion, Variants } from "framer-motion";
import Link from "next/link";
import BlackholeIcon from "~web/app/(evm)/_common/blackhole-icon";
import { Button } from "@sushiswap/ui";
import HeroBg from "../assets/hero-bg.svg";
import TopAccentLine from "../assets/top-accent-line.svg";
import { ScrollDownButton } from "./scroll-down-button";

const containerVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
};

const itemVariants: Variants = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 15,
    },
  },
};

export function HeroSection() {
  return (
    <section className="min-h-view grid place-items-center relative">
      <TopAccentLine className="absolute top-0 right-0" />
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1.5, opacity: 0.2 }}
        transition={{ duration: 0.8, ease: "backOut" }}
        className="absolute pointer-events-none"
      >
        <HeroBg />
      </motion.div>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid container place-items-center gap-2 relative"
      >
        <motion.h1 variants={itemVariants} className="text-5xl font-bold">
          Welcome to
        </motion.h1>
        <motion.div variants={itemVariants}>
          <BlackholeIcon width={943} />
        </motion.div>
        <motion.p
          variants={itemVariants}
          className="font-bold text-2xl mb-6 text-center"
        >
          The central trading and liquidity hub for Gaming on BASE
        </motion.p>
        <motion.div variants={itemVariants}>
          <Button asChild size="xl">
            <Link href="/swap">Launch Blackhole App</Link>
          </Button>
        </motion.div>
      </motion.div>
      <ScrollDownButton targetId="what-is-blackhole" />
    </section>
  );
}
